import React from 'react'
import { Helmet } from 'react-helmet'
import { Fade } from 'react-reveal';
import Layout from '../../components/layout'

const Software = props => (
    <Layout>
        <Helmet>
            <title>Software Licensing</title>
            <meta name="description" content="Carboncrediting Info" />
        </Helmet>
        <Fade duration={2500}>
            <div id="main" className="wrapper style1">
                <div className="container">
                    <header className="major">
                        <h2>Software Licensing</h2>
                        <p>
                        Adoption of both old and new licensing methods to provide services.
                        </p>
                    </header>
                    
                    <section>
                        <div className="col-4 col-6-medium col-12-small col-12-xsmall">
                            <div style={{textAlign:`left`}}><b>We have done and developed various products catered to the education and agriculture sector.</b>
                                
                            </div>
                            <br/>
                            <div style={{textAlign:`left`}}><b>Products and software of ours are available for different licensing options including: </b></div>
                        
                        </div>
                        <br/>
                        
                        <div className="col-12 col-6-medium col-12-small col-12-xsmall">
                            <ul  style={{listStylePosition:`inside`}}>
                                <li><b>Perpetual Licensing</b></li>
                                <li><b>Shared Licensing</b></li>
                                <li><b>Subscription Licensing</b></li>
                                <li><b>Usage-based Licensing</b></li>
                            </ul>
                        </div>
                        <div className="col-4 col-6-medium col-12-small col-12-xsmall">
                            <div style={{textAlign:`left`}}>
                                <b><a href="/contactus">Contact Us</a> to find out more!</b>
                            </div>
                        
                        </div>
                    </section>
                
                </div>
            </div>
        </Fade>
    </Layout>
)

export default Software